import { useContext, useEffect, useState } from "react";
import PopUp from "./PopUp";
import { AppContext } from "../App";
import api from "./api/api";
import Table from "./ui/Table";

function SellsList() {
	const {
		config,
		setSellsListVisible,
		sells,
		setConfirm,
		loadSells,
		apiSettings,
		updateOrder,
		sellsFilter,
		setSellsFilter,
	} = useContext(AppContext);

	useEffect(() => {
		loadSells();
	}, []);

	const cancelOrder = async (order) => {
		console.log("cancelOrder");

		const res = await api.postProtected("/fiscal_printers/send_command/", {
			command: "CANCEL_ORDER",
			order: order,
		});
		if (res.success == 0)
			setConfirm({
				title: "Errore",
				message: res.error,
			});
	};

	return (
		<PopUp
			size="large"
			options={{
				onClose: () => setSellsListVisible(false),
				title: "Vendite",
				headerButtons: (
					<input
						type="date"
						value={sellsFilter}
						onChange={(e) => setSellsFilter(e.target.value)}
					/>
				),
				content: (
					<Table
						header={true}
						config={config}
						cols={[
							{ id: "id", label: "ID", type: "id", align: "center" },
							{
								id: "delivery_type",
								label: "Tipo",
								type: "delivery_type",
								align: "center",
							},
							{
								id: "delivery_date",
								label: "Ora",
								type: "delivery_date",
								align: "center",
							},
							{
								id: "origin",
								label: "Origine",
								type: "origin",
								align: "center",
							},
							{ id: "customer", label: "Cliente", type: "customer" },
							{ id: "info", label: "Info", type: "info" },
							{ id: "total", label: "Totale", type: "eur", align: "right" },
							{
								id: "bill",
								label: "Scontrino",
								type: "bill",
								align: "center",
								visible:
									apiSettings.mandant.fiscal_printers == 1 ||
									apiSettings.mandant.fiskaly == 1,
							},
							{
								id: "buttons",
								label: "",
								type: "buttons",
								buttons: [
									{
										label: "Riprova scontrino",
										onClick: (order) => {
											setConfirm({
												title: "Sei sicuro?",
												onConfirm: () => {
													updateOrder(order, {
														bill: 1,
														bill_number: null,
														fiscal_printer_id:
															config.fiscal_printer_id || null,
													});
												},
											});
										},
										visible: (order) => order.bill == 2,
									},
									{
										label: "Preconto",
										onClick: (order) => {
											setConfirm({
												title: "Sei sicuro?",
												onConfirm: () => {
													updateOrder(order, {
														prebill_request: 1,
														prebill_printed: 0,
													});
												},
											});
										},
										visible: true,
									},
									{
										label: "Annulla Vendita",
										onClick: (order) => {
											setConfirm({
												title: "Sei sicuro?",
												onConfirm: () => {
													cancelOrder(order);
													setSellsListVisible(false);
												},
											});
										},
										visible: (order) =>
											order.bill == 3 &&
											apiSettings.mandant.fiscal_printers == 1,
									},
								],
							},
						]}
						rows={sells}
					/>
				),
			}}
		/>
	);
}

export default SellsList;
