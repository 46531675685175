import { useContext } from "react";
import { AppContext } from "../App";

export default function HeaderRight() {
	const {
		apiSettings,
		shop,
		iframeMode,
		setOrdersListVisible,
		orders,
		tables,
		setTablesListVisible,
		config,
	} = useContext(AppContext);

	return (
		<div className="header-right">
			<button onClick={() => setTablesListVisible(true)}>
				<div className="badge">
					{tables.filter((t) => t.shop_id == shop.id && t.status != 0).length}
				</div>
				Tavoli
			</button>
			{orders && (
				<button onClick={() => setOrdersListVisible(true)}>
					<div className="badge">{orders.length}</div>
					Ordini
				</button>
			)}
			{iframeMode && (
				<button
					className="header-close-button"
					onClick={() => {
						window.parent.postMessage("close", "*");
					}}
				>
					&times;
				</button>
			)}
		</div>
	);
}
