import { useContext } from "react";
import { AppContext } from "../../App";

function Flows() {
	const { activeFlow, setActiveFlow, config, table, cart } = useContext(AppContext);
	const flows = config.flows_number
		? [...Array(parseInt(config.flows_number)).keys()]
		: [...Array(parseInt(5)).keys()];

	if (!config.enable_flows == 1) return null;
	if (!cart.table) return null;
	if (table.single_order_mode == 1) return null;

	return (
		<div className="cart-flows-buttons">
			{flows.map((el, i) => (
				<button
					key={i}
					className={activeFlow == el + 1 ? "selected" : ""}
					onClick={() => setActiveFlow(el + 1)}
				>
					{"Portata " + (el + 1)}
				</button>
			))}
		</div>
	);
}

export default Flows;
